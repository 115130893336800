.spinnerContainer {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: conic-gradient(#fff 10%, #019b15);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 5px),
    #000 0
  ); /* Width of spinner */
  animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
