@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

/* CSS Document */
.BTN_Standard {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  color: #FFF;
  background-color: #427789;
  background: linear-gradient(to right, #214957 50%, #427789 50%);
  padding: 8px 20px;
  display: block;
  width: 90%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  border: 0px;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: right bottom;
  margin: 0 auto;
  margin-top: 10px;
  transition: all 0.3s ease; }

.BTN_Standard:hover {
  background-position: left bottom; }

.BTN_Secondary {
  color: #FFF;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  color: #FFF;
  background-color: #182a38;
  background: linear-gradient(to right, #182a38 50%, #447092 50%);
  padding: 8px 20px;
  display: block;
  width: 90%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  border: 0px;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: right bottom;
  margin: 0 auto;
  margin-top: 10px;
  transition: all 0.3s ease; }

.BTN_Secondary:hover {
  background-position: left bottom; }

.BTN_Subtle {
  color: #adadad;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  color: #4f4f4f;
  background-color: #F7EBDB;
  background: linear-gradient(to right, #e79c45 50%, #F7EBDB 50%);
  padding: 8px 20px;
  display: block;
  width: 90%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  border: 0px;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: right bottom;
  margin: 0 auto;
  margin-top: 10px;
  transition: all 0.3s ease; }

.BTN_Subtle:hover {
  background-position: left bottom;
  color: #FFF; }

.BTN_Delete {
  color: #adadad;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  color: #4f4f4f;
  background-color: #B5B5B5;
  background: linear-gradient(to right, #A72000 50%, #B5B5B5 50%);
  padding: 8px 20px;
  display: block;
  width: 90%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  border: 0px;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: right bottom;
  margin: 0 auto;
  margin-top: 10px;
  transition: all 0.3s ease; }

.BTN_Delete:hover {
  background-position: left bottom;
  color: #FFF; }

.BTN_Story {
  width: 100px;
  margin: 5px; }

.BTN-mapFunction {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
  border: 1px solid #427789;
  background-color: #fff;
  margin: 5px; }

.BTN-mapFunction:hover {
  background-color: #d5d5d5; }

.BTN-W-Icon {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  position: relative;
  display: inline-block;
  height: 32px;
  width: 32px;
  background-color: #fff;
  margin: 5px; }

.BTN-Edit {
  background-image: url("/public/media/images/icon-edit.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px; }

.BTN-Delete {
  background-image: url("/public/media/images/icon-delete.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px;
  background-color: #FFF;
  border: 1px solid #427789; }

.BTN-Delete:hover {
  background-image: url("/public/media/images/icon-delete-white.svg");
  background-color: #427789; }

.BTN-EditB {
  background-image: url("/public/media/images/icon-edit.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px;
  background-color: #FFF;
  border: 1px solid #427789; }

.BTN-EditB:hover {
  background-image: url("/public/media/images/icon-edit-white.svg");
  background-color: #427789; }

/* Error Checking */
.MSGSuccess {
  box-sizing: border-box;
  border: 2px solid;
  border-top: 6px solid;
  margin-bottom: 20px;
  padding: 20px;
  border-color: #7dbd0d;
  color: #7dbd0d; }

.MSGError {
  box-sizing: border-box;
  border: 2px solid;
  border-top: 6px solid;
  margin-bottom: 20px;
  padding: 20px;
  border-color: #b4282f;
  color: #b4282f; }

.LoginLogo {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 200px; }

.spinnerContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.spinner {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  /*    background: conic-gradient(#0000 10%, var(--color-light--2));
      -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);*/
  animation: rotate 1.5s infinite linear; }

.SideNav {
  position: relative;
  float: left;
  left: 0;
  top: 0;
  height: calc(100vh - 86px);
  width: 250px;
  border-right: 1px solid #333;
  background-color: #fff; }

.SideNav ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.SideNav ul li {
  display: block;
  width: 100%;
  height: 52px; }

.SideNav ul li a {
  border-left: 6px solid #FFF;
  height: 52px;
  display: block; }

.SideNav ul li a:hover {
  border-left: 6px solid #427789; }

.SideNav ul li a.active {
  border-left: 6px solid #a72000;
  background-color: #F4F4F4; }

.SideNav ul li img {
  position: relative;
  vertical-align: top;
  display: inline-block;
  height: 36px;
  width: 36px;
  padding: 10px;
  box-sizing: content-box; }

.SideNav ul li span {
  position: relative;
  vertical-align: top;
  display: inline-block;
  padding-top: 16px;
  color: #182a38;
  font-weight: bold; }

.SideNav + section {
  position: relative;
  display: inline-block;
  width: calc(100% - 250px);
  left: 0;
  top: 0;
  padding: 20px; }

.MapSection {
  padding: 0px !important; }

body {
  background-color: #f6f6f6;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

H1, H2, H3, H4, H5 {
  font-family: "Roboto", sans-serif;
  margin: 0; }

H2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #427789; }

H3 {
  font-size: 22px;
  line-height: 50px;
  font-weight: 700;
  color: #182a38;
  display: block;
  padding: 0;
  margin: 0; }

header {
  position: relative;
  height: 85px;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid #4f4f4f;
  box-sizing: content-box; }

header .LogoIcon {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #d5d5d5;
  display: inline-block;
  height: 85px;
  width: 85px; }

header .LogoIcon img {
  height: 85px;
  width: 85px; }

header .Logo {
  position: absolute;
  left: 85px;
  top: 0;
  display: inline-block;
  height: 85px;
  width: auto; }

header .Logo img {
  height: 85px; }

header .UserInfo {
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  height: 85px;
  width: auto; }

header .UserProfile {
  position: relative;
  display: flex;
  right: 0;
  top: 20px;
  margin-right: 20px;
  height: fit-content;
  padding: 5px;
  border-radius: 10px; }

header .UserProfile:hover {
  background-color: #F1F1F1; }

header .UserProfile P {
  padding: 0;
  margin: 0;
  vertical-align: top; }

header .UserProfile strong {
  display: block;
  vertical-align: top; }

header .UserProfile img {
  display: inline-block;
  height: 44px;
  width: 44px;
  margin-right: 10px;
  border-radius: 30px;
  vertical-align: top; }

.UserMenu {
  position: relative;
  display: block;
  border: 2px solid #427789;
  background-color: #fff;
  padding: 10px;
  z-index: 1000; }

.instructions {
  border: 1px solid #6EB1C8;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.NotificationsMenu {
  display: inline-block;
  margin: 20px 0 0 0; }

.Notifications {
  background-image: url("/public/media/images/icon-Notification.svg");
  background-position: center center;
  font-size: 18px;
  color: #427789;
  height: 40px;
  min-width: 60px;
  width: auto;
  display: inline-block;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 25px;
  border: 2px solid #427789;
  background-color: #fff;
  margin: 5px 10px;
  padding: 5px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.DotMenu {
  background-image: url("/public/media/images/icon-Menu.svg");
  background-position: center center;
  font-size: 18px;
  color: #427789;
  height: 40px;
  min-width: 60px;
  width: auto;
  display: inline-block;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 25px;
  border: 2px solid #427789;
  background-color: #fff;
  margin: 5px 10px;
  padding: 5px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.DotMenu:hover {
  background-color: #d5d5d5; }

.HomeCards li {
  display: inline-block;
  width: 250px;
  padding: 10px;
  margin: 20px;
  border: 5px solid #eee;
  text-align: center;
  vertical-align: top;
  border-radius: 20px; }

.HomeCards li img {
  display: block;
  height: 100px;
  width: 100px;
  margin: 0 auto; }

.ProfileHeader {
  position: relative;
  margin: 0;
  padding: 0;
  height: 640px;
  width: 100%; }

.ProfileAvatar {
  position: relative;
  display: block;
  top: -125px;
  left: 75px; }

.ProfileAvatar img {
  border: 5px solid #fff;
  width: 250px;
  height: 250px;
  min-height: 250px;
  min-width: 250px;
  border-radius: 100%; }

.ProfileAvatar .Controls {
  display: none; }

.ProfileAvatar:hover .Controls {
  display: block;
  position: absolute;
  top: 90px;
  left: 85px; }

.ProfileMasthead {
  position: relative;
  display: block;
  min-width: 350px;
  min-height: 250px; }

.ProfileMasthead img {
  width: 100%;
  max-width: 1200px;
  max-height: 500px;
  height: auto;
  border-radius: 20px; }

.ProfileMasthead .Controls {
  display: none; }

.ProfileMasthead:hover .Controls {
  display: block;
  position: absolute;
  top: 90px;
  left: 85px; }

.ProfileDetails {
  position: relative;
  top: -230px;
  left: 340px; }

.ProfileDetails H1 {
  font-size: 40px;
  line-height: 45px;
  color: #427789; }

.ProfileDetails i {
  color: #a72000; }

.ChartDiv {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden; }

.MemorySnapshot {
  vertical-align: top;
  width: 30%;
  min-width: 320px;
  max-width: 500px;
  position: relative;
  display: inline-block;
  background-color: #fff;
  font-size: 24px;
  line-height: 24px;
  margin-top: 20px;
  text-decoration: none;
  margin-left: 10px;
  transition: all 0.3s ease;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.MemorySnapshot IMG {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin: 10px 0 10px 20px; }

.MemorySnapshot .Friends {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex; }

.MemorySnapshot .Friends IMG {
  position: relative;
  display: inline-block;
  float: right;
  right: 50px;
  margin-right: -30px;
  height: 30px;
  width: 30px;
  border-radius: 15px; }

.MemorySnapshot .Friends .FriendCount {
  position: absolute;
  z-index: 500;
  right: 0px;
  margin: 10px 0px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  background-color: #182a38; }

.MemorySnapshot H3 {
  font-style: normal;
  font-size: 26px;
  line-height: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin: 0 20px;
  width: 90%;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.MemorySnapshot .DateRange {
  font-size: 14px;
  font-weight: 600;
  color: #427789;
  font-family: "Roboto", sans-serif;
  margin: 0 20px;
  padding-left: 25px;
  background-image: url("/public/media/images/icon-Calendar.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px; }

.MemorySnapshot P {
  margin: 5px 20px 15px;
  font-size: 14px;
  line-height: 20px;
  height: 60px;
  color: #427789;
  font-family: "Roboto", sans-serif;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.MemorySnapshot .Controls {
  border-top: 1px solid #B5B5B5; }

.Controls {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center; }

.Controls button {
  font-size: 18px;
  color: #427789;
  height: 40px;
  min-width: 60px;
  width: auto;
  display: inline-block;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 25px;
  border: 2px solid #427789;
  background-color: #fff;
  margin: 5px 10px;
  padding: 5px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.Controls button:hover {
  background-color: #d5d5d5; }

.MemorySnapshot .Controls .Divider {
  display: inline-block;
  height: 40px;
  width: 1px;
  background-color: #B5B5B5; }

button.Gear {
  background-image: url("/public/media/images/icon-Gear.svg");
  background-position: center center; }

button.Images {
  background-image: url("/public/media/images/icon-Images.svg");
  background-position: 10px center; }

button.Chat {
  background-image: url("/public/media/images/icon-Chat.svg");
  background-position: 10px center; }

/* Story LIst Begin */
.StoryList {
  position: relative;
  padding: 0;
  margin: 50px 0 0 0; }

.StoryList .Story {
  position: relative;
  padding: 20px 0;
  margin: 0 0 20px 0;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.StoryList .Story .StoryDate {
  position: relative;
  padding: 0;
  margin: 0;
  display: inline-block;
  width: 220px;
  height: 100%;
  min-height: 100px;
  vertical-align: top; }

.StoryList .Story .StoryDate img {
  height: 50px;
  width: 50px; }

.StoryList .Story .StoryDate P {
  position: absolute;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-weight: 600;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%); }

.StoryList .Story .StoryText {
  box-sizing: border-box;
  vertical-align: top;
  position: relative;
  padding: 0 0 0 50px;
  margin: 0;
  display: inline-block;
  width: calc(100% - 220px);
  border-left: 7px solid #a72000; }

.StoryList .Story .StoryText .StoryContent {
  white-space: pre-line;
  font-size: 18px;
  line-height: 30px; }

.StoryList .Story .StoryText .TagList {
  padding: 0;
  margin: 0;
  list-style: none; }

.StoryList .Story .StoryText .TagList LI {
  display: inline-block;
  margin: 0 10px; }

.StoryList .Story .StoryText .TagList LI A {
  text-decoration: none;
  font-weight: 600;
  color: #a72000;
  padding: 5px 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.StoryList .Story .StoryText .TagList LI A:hover {
  color: #FFF;
  background-color: #a72000; }

.StoryList .Story .StoryText .MediaList .MediaItem {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 200px;
  height: 133px;
  vertical-align: unset; }

.Story .BTN_Subtle {
  display: none; }

.Story:hover {
  background-color: #ffffff; }

.Story:hover .BTN_Subtle {
  display: block; }

.Story .LocationList {
  padding: 0;
  margin: 0; }

.Story .LocationList li {
  list-style: none;
  margin: 5px; }

.Story .LocationList img {
  background-color: #216ba5;
  padding: 5px;
  width: 35px;
  border-radius: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; }

/*  Public Story */
.PublicStory {
  position: relative;
  margin: 0;
  padding: 0; }

.PublicStory .Story {
  display: block;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.PublicStory .Story .StoryText .TagList {
  list-style: none;
  margin: 0;
  padding: 0; }

.PublicStory .Story .StoryText .TagList li {
  display: inline-block;
  padding: 5px; }

.MediaDetail {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  position: relative;
  background-color: #fff;
  font-size: 24px;
  line-height: 24px;
  margin: 20px;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 20px; }

.MediaDetail img {
  max-width: 500px;
  max-height: 500px;
  margin: 10px auto;
  display: block; }

.MediaList {
  position: relative; }

.MediaList .MediaItem {
  position: relative;
  display: inline-block;
  max-width: 170px;
  vertical-align: top; }

.MediaList .MediaItem P {
  padding: 0;
  margin: 0 20px;
  font-size: 14px;
  font-weight: 700; }

.MediaList .MediaItem em {
  padding: 0;
  margin: 0 20px;
  font-size: 14px; }

.MediaList .MediaItem .Controls {
  display: none; }

.MediaList .MediaItem:hover .Controls {
  display: block;
  position: absolute;
  top: 0;
  right: 0; }

.MediaList .Controls button {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px; }

.MediaAssocs {
  margin-top: 40px; }

.MediaAssocs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 10px; }

.MediaAssocs ul li {
  padding: 5px;
  border-bottom: 1px solid #c3c3c3; }

.MediaAssocs ul li span {
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  width: 80%;
  vertical-align: text-bottom; }

.MediaAssocs ul li:hover {
  background-color: #6EB1C8;
  color: #fff;
  cursor: pointer; }

.Functions {
  position: absolute;
  top: 0px;
  right: 0px; }

.Dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; }

.DialogText {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 15px;
  border: 1px solid #F1F1F1;
  width: 300px;
  height: 250px;
  z-index: 1100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.DialogText H3 {
  display: block;
  text-align: center; }

.Controls {
  display: flex;
  align-items: center; }

.MediaDisplay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; }

.MediaDisplayText {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 15px;
  border: 1px solid #F1F1F1;
  width: 50%;
  height: auto;
  z-index: 1100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.MediaDisplayText H3 {
  display: block;
  text-align: center; }

.MediaDisplayText img {
  max-width: 80%;
  max-height: calc(100vh - 250px); }

.Blue1 {
  border-color: #427789 !important; }

.Blue2 {
  border-color: #182a38 !important; }

.Orange {
  border-color: #e79c45 !important; }

.Red {
  border-color: #a72000 !important; }

/* Story LIst End */
.FriendRequest {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: #B5B5B5; }

.CurrentFriends {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: #d5d5d5; }

.FriendSearchContainer {
  display: block;
  border: 1px solid #B5B5B5;
  background-color: #d9d9d9;
  margin: 0 auto;
  max-width: 750px;
  border-radius: 0 0 20px 20px;
  margin-top: -20px;
  padding-bottom: 20px;
  text-align: center; }

.FriendSearchBox {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  position: relative;
  display: inline-block;
  background-color: #fff;
  margin-top: 5px;
  margin-left: 10px;
  transition: all 0.3s ease;
  border: 1px solid #B5B5B5; }

.FriendSearchBox:hover {
  background-color: #f1f1f1; }

.FriendSearchBox span {
  height: 80px;
  width: 80px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  background-size: cover;
  background-position: center center; }

.FriendSearchBox h3 {
  display: inline-block;
  font-size: 20px;
  line-height: 18px;
  padding: 10px;
  vertical-align: top; }

.FriendSearchBox h3 i {
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: 28px; }

.FriendSearchBox .BTN_Standard {
  display: inline-block !important;
  font-size: 16px;
  line-height: 18px;
  padding: 10px;
  vertical-align: top;
  width: 110px;
  margin-right: 10px; }

.FriendSearchBox .BTN_Secondary {
  display: inline-block !important;
  font-size: 16px;
  line-height: 18px;
  padding: 10px;
  vertical-align: top;
  width: 110px;
  margin-right: 10px; }

.FriendBox {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  position: relative;
  display: inline-block;
  background-color: #fff;
  font-size: 24px;
  line-height: 24px;
  margin-top: 20px;
  text-decoration: none;
  margin-left: 10px;
  transition: all 0.3s ease;
  border: 1px solid #B5B5B5;
  padding-bottom: 10px; }

.FriendBox span {
  height: 175px;
  width: 175px;
  overflow: hidden;
  display: inline-block;
  background-size: cover;
  background-position: center center; }

.FriendBox h3 {
  font-size: 16px;
  line-height: 16px;
  padding: 0 10px; }

.FriendBox p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  padding: 0 10px;
  color: #427789; }

.FriendBox .MutualFriends {
  color: #B5B5B5;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  padding: 0 20px; }

.FriendBox .MutualFriends IMG {
  position: relative;
  display: inline-block;
  float: left;
  left: -10px;
  margin-right: -5px;
  height: 15px;
  width: 15px;
  border-radius: 15px; }

.PopForm {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.65);
  display: block;
  height: 100%;
  width: 100%;
  z-index: 500; }

.PopForm form {
  background: #fff;
  border: 1px solid #182a38;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  width: 70%;
  max-width: 750px;
  margin: 100px auto;
  padding: 40px; }

.showForm {
  display: block; }

.PopForm label {
  padding: 5px 0px;
  margin: 8px 0;
  display: block; }

.PopForm input[type=text], .PopForm textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 0px 0px 10px;
  box-sizing: border-box;
  border: 2px solid #B5B5B5;
  border-radius: 6px; }

.PopForm input[type=date] {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #B5B5B5;
  border-radius: 6px; }

.PopForm textarea {
  height: 300px; }

.PopForm input:focus, textarea:focus {
  border-color: #e79c45; }

.MediaList img {
  display: inline-block;
  max-width: 150px;
  margin: 20px; }

.TagList {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0; }

.TagList li {
  padding: 5px 0px; }

.TagList li input {
  padding: 10px;
  vertical-align: text-bottom;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  background-color: #FFF;
  border: 0px solid #FFF; }

.TagList li input:focus {
  background-color: #fff;
  border: #427789; }

/*1700*/
/*1440*/
/*1280*/
/*960*/
@media screen and (max-width: 974px) {
  .SideNav + section {
    width: calc(100% - 70px); }

  .SideNav {
    width: 70px; }

  .SideNav ul li span {
    display: none; } }
/*768*/
@media screen and (max-width: 782px) {
  header .UserProfile {
    display: none; }

  .StoryList .Story {
    border-left: 7px solid #a72000;
    padding: 0 0 0 15px; }

  .StoryList .Story .StoryDate {
    width: 100%;
    height: auto;
    min-height: 30px; }

  .StoryList .Story .StoryDate P {
    position: absolute;
    padding: 0;
    margin: 0;
    display: inline-block;
    font-weight: 600;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%); }

  .StoryList .Story .StoryText {
    padding: 0;
    width: 100%;
    border-left: 0px; } }
/*600*/
/*480*/
/*340*/
.FormLeft, .FormRight {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box; }

.FatForm {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  background: #FFF;
  padding: 20px 30px 20px 30px;
  color: #888;
  border: 1px solid #DDD;
  max-width: 750px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.FatForm h1 {
  padding: 0px 0px 10px 40px;
  display: block;
  border-bottom: 1px solid #DADADA;
  margin: -10px -30px 30px -30px;
  color: #888; }

.FatForm h1 > span {
  display: block;
  font-size: 11px; }

.FatForm label {
  float: left;
  width: 20%;
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 3px;
  margin-right: 10px;
  color: #333;
  font-weight: bold; }

.FatForm input[type="text"], .FatForm input[type="email"], .FatForm input[type="tel"], .FatForm input[type="date"], .FatForm textarea, .FatForm select, .FatForm input[type="password"], .FatForm input[type="time"], .FatForm input[type="number"] {
  border: 1px solid #CCC;
  color: #888;
  margin-bottom: 16px;
  margin-right: 6px;
  margin-top: 2px;
  outline: 0 none;
  padding: 10px;
  width: 70%;
  font-size: 18px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.FatForm .smallField {
  width: 50% !important;
  max-width: 350px;
  min-width: 200px; }

.FatForm .minifield {
  border: 1px solid #CCC;
  color: #888;
  height: 20px;
  line-height: 15px;
  margin-bottom: 16px;
  margin-right: 6px;
  margin-top: 2px;
  outline: 0 none;
  padding: 5px 0px 5px 5px;
  width: 40px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.FatForm textarea {
  height: 100px;
  padding: 5px 0px 0px 5px;
  width: 70%; }

.FatForm ul.Horiz {
  display: block; }

.FatForm ul.Horiz li {
  display: inline-block; }

.FatForm ul.Vert {
  padding-left: 120px; }

.FatForm ul.Vert li {
  list-style: none;
  display: block; }

.FatForm .button {
  background: #FFF;
  color: #333;
  padding: 8px 20px;
  display: block;
  width: 90%;
  font-size: 14px;
  line-height: 18px;
  border: 0px;
  text-decoration: none;
  background-size: 200% 100%;
  background-position: right bottom;
  margin: 0 auto;
  margin-top: 10px;
  transition: all 0.3s ease;
  margin-left: 21%; }

.FatForm .button:hover {
  color: #333;
  background-color: #EBEBEB;
  border-color: #ADADAD; }

.form-group {
  display: flex !important;
  flex-direction: row;
  margin: 10px 0;
  clear: both; }

.form-group > div {
  /*display: flex;*/
  justify-content: center;
  align-items: center; }

.form-group > div > div {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center; }

.form-group > div > div > label {
  width: auto !important; }

#CustomForm li {
  list-style: none; }

.form-group li > label {
  float: right;
  width: auto;
  padding-left: 10px;
  font-weight: normal; }

.form-group li {
  padding-top: 6px; }

.Vertical li > label {
  float: right;
  width: auto;
  padding-left: 10px;
  font-weight: normal;
  display: contents; }

.FullWidth, .FullWidth label, .FullWidth ul {
  width: 100%;
  display: block; }

.FullWidth .form-group {
  display: block !important; }

.AccountForm {
  position: relative;
  margin: 20 auto;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  z-index: 100;
  background-color: #fff; }

/*
input[type=text]{
    width: 500px;
    padding: 10px;
    font-size: 18px;
}

input[type=text], input[type=password] {
    width: 500px;
    padding: 10px;
    font-size: 18px;
}

label{
    display: inline-block;
    width: 150px;
    padding: 10px;
    font-size: 18px;
}*/
.AddLocationForm {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #b9b9b9;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); }

.MapForm {
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #b9b9b9;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); }

.MapForm label {
  display: inline-block;
  width: 150px;
  padding: 10px;
  font-size: 18px; }

.MapForm .SearchDate {
  width: 150px !important; }

.MapButton {
  font-size: 18px;
  color: #427789;
  height: 40px;
  min-width: 60px;
  width: auto;
  display: inline-block;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 25px;
  border: 2px solid #427789;
  background-color: #fff;
  margin: 5px 10px;
  padding: 5px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px; }

.MapButton:hover {
  background-color: #427789;
  color: #fff; }

.MapForm input[type=text] {
  width: 500px;
  padding: 10px;
  font-size: 18px; }

.AddStory {
  display: block;
  margin-top: 10px; }

.LocStoryLabel {
  vertical-align: top; }

.LocStoryInput {
  vertical-align: top;
  width: 500px;
  padding: 10px;
  font-size: 18px;
  height: 60px; }

.RadioBtnGroup {
  display: block; }

.RadioBtnGroup p {
  display: block;
  padding: 10px;
  font-size: 18px;
  margin: 0; }

.RadioBtn {
  display: inline-block;
  cursor: pointer;
  border: 2px solid #7dbd0d;
  margin: 5px 10px !important;
  color: #7dbd0d;
  background-color: #FFF;
  padding: 10px;
  width: 75px !important;
  text-align: center; }

.inline {
  display: inline-block !important;
  margin: 10px; }

.MapForm .showStory {
  background-color: #7dbd0d;
  color: #fff;
  display: block;
  border: 2px solid #7dbd0d;
  display: inline-block;
  margin-left: 3px;
  height: 36px; }

.MapForm input[type=radio], .PopForm input[type=radio] {
  display: none; }

.MapForm label:has(+ input[type=radio]:checked), .PopForm label:has(+ input[type=radio]:checked) {
  border: 2px solid #7dbd0d;
  background-color: #7dbd0d;
  color: #FFF; }

.FormAvatar {
  height: 200px;
  width: 200px;
  border-radius: 100%; }

.FormMasthead {
  height: auto;
  width: 640px; }

/*1700*/
/*1440*/
/*1280*/
@media screen and (max-width: 1376px) {
  #GMap {
    height: 50vh !important; }

  .MapForm {
    position: relative;
    top: 0;
    right: 0px;
    padding: 10px;
    width: 100%; }

  .MapForm H2 {
    display: none; }

  .MapForm input[type=text] {
    width: 100%;
    padding: 5px; }

  .RadioBtn {
    width: 50px !important;
    height: 50px;
    padding: 5px;
    margin: 5px 5px; }

  .RadioBtn span {
    display: none; }

  .RadioBtn img {
    width: 34px;
    height: 34px; }

  .MapForm label {
    width: 70px; }

  .LocTitleInput {
    width: 80% !important; }

  .LocStoryInput {
    padding: 5px;
    width: 80% !important; } }
/*960*/
/*768*/
@media screen and (max-width: 782px) {
  .FormLeft, .FormRight {
    width: 100%; } }
/*600*/
/*480*/
/*340*/
